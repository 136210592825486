import React, { useState, useEffect, useContext, useRef } from "react";
import numeral from "numeral";
import moment from "moment";
import AsyncSelect from "react-select/async";
import { apiGet, apiPost } from "../../../../shared/api";
import UserContext from "../../../../context/user/UserContext";
import { useParams } from "react-router-dom";
import Modal from "../../../../shared/ModalContent";
import _ from "lodash";
import LoadingIndicator from "../../../../shared/LoadingIndication";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { GetHeightFromId } from "../../../../shared/functions";

const ClassView = (props) => {
  const [ShowMessageModal, setShowMessageModal] = useState(false);
  const [MessageModalContent, setMessageModalContent] = useState();
  const [Checked, setChecked] = useState(false);
  const [ShowPairModal, setShowPairModal] = useState(false);
  const [ShowTeamModal, setShowTeamModal] = useState(false);
  const [DefaultHandler, setDefaultHandler] = useState();
  const [ShowInvalidHeight, setShowInvalidHeight] = useState(false);
  const [TeamCanSave, setTeamCanSave] = useState(false);
  const [PairCanSave, setPairCanSave] = useState(false);

  const [TPClassId, setTPClassId] = useState();
  const [TPClassDate, setTPClassDate] = useState();
  const [TPTeamName, setTPTeamName] = useState("");
  const [SecondTeamHandler, setSecondTeamHandler] = useState();
  const [ThirdTeamHandler, setThirdTeamHandler] = useState();
  const [SecondPairHandler, setSecondPairHandler] = useState();
  const [SecondTeamDog, setSecondTeamDog] = useState();
  const [SecondTeamDogHeight, setSecondTeamDogHeight] = useState();
  const [ThirdTeamDog, setThirdTeamDog] = useState();
  const [ThirdTeamDogHeight, setThirdTeamDogHeight] = useState();
  const [SecondPairDog, setSecondPairDog] = useState();
  const [SecondPairDogHeight, setSecondPairDogHeight] = useState();
  const [SecondPairDogSector, setSecondPairDogSector] = useState("");
  const [IsAdminLogin, setIsAdminLogin] = useState(false);
  const [TeamPairMember, setTeamPairMember] = useState(false);
  const [ShowChooseHeight, setShowChooseHeight] = useState(false);
  const [CKOHeightId, setCKOHeightId] = useState(props.Class.HeightId);
  const [CKOHeightSelection, setCKOHeightSelection] = useState(props.Class.HeightId);
  const [AvailableCKOHeights, setAvailableCKOHeights] = useState([]);
  const [HeightLabel, setHeightLabel] = useState(props.Class?.HeightLabel);

  const itm = props.Class;
  let Available = !itm.Capped;
  const { ShowId } = useParams();
  const { User } = useContext(UserContext);
  const Loading = useRef(true);

  const MakeCKOHeightSelection = () => {
    setCKOHeightId(parseInt(CKOHeightSelection));
    MakeSelection(itm.ClassType, itm.ClassId, itm.ClassDate, parseInt(CKOHeightSelection), itm.LevelId, true);
    setShowChooseHeight(false);
  };

  useEffect(() => {
    if (props.Class?.ClassId === 25) setHeightLabel(GetHeightFromId(parseInt(CKOHeightId)));
  }, [CKOHeightId]);

  useEffect(() => {
    (async () => {
      const obj = {
        ShowId: parseInt(ShowId),
        ClassDate: props.Class.ClassDate,
        ClassId: props.Class.ClassId,
        DogId: parseInt(props.DogId),
      };
      const req = await apiPost("/shows/isteamorpairmember", obj);
      const res = await req.json();
      setTeamPairMember(res.Outcome === "No" ? 0 : 1);
    })();
  }, [props.DogId]);

  useEffect(() => {
    if (props.Data.Dogs) {
      const FoundDog = props.Data.Dogs.find((d) => d.Id === parseInt(props.DogId));
      let _DefaultHandler = {
        value: User.Id,
        label: `${User.Id} - ${User.FirstName} ${User.LastName}`,
      };
      const DogRecord = props.Data.DogList.find((d) => d.Id === parseInt(props.DogId));
      if (DogRecord && DogRecord.DefaultHandlerId !== User.Id)
        _DefaultHandler = {
          value: DogRecord.DefaultHandlerId,
          label: `${DogRecord.DefaultHandlerId} - ${DogRecord.FirstName} ${DogRecord.LastName}`,
          name: `${DogRecord.FirstName} ${DogRecord.LastName}`,
        };
      const Choices = FoundDog?.Choices || [];

      const found = Choices.find(
        (c) =>
          moment(c.ClassDate).isSame(itm.ClassDate) &&
          c.ClassId === itm.ClassId &&
          (c.HeightId === itm.HeightId || c.ClassId === 25) &&
          c.LevelId === itm.LevelId
      );
      if (found) {
        setChecked(true);
        setCKOHeightId(found.HeightId);
        setCKOHeightSelection(found.HeightId);
        //if(found.HandlerId !== User.Id) {
        _DefaultHandler.value = found.HandlerId;
        _DefaultHandler.label = `${found.HandlerId} - ${found.FirstName} ${found.LastName}`;
        _DefaultHandler.name = `${found.FirstName} ${found.LastName}`;
        //}
      } else {
        setChecked(false);
      }
      setDefaultHandler(_DefaultHandler);

      if (props.Class.ClassId === 25) {
        const foundDog = props.Data.DogList.find((d) => d.Id === parseInt(props.DogId));
        if (foundDog) {
          const found = props.Data.AllClassCombinations.filter(
            (c) =>
              c.ClassId === 25 &&
              moment(c.ClassDate).isSame(moment(props.Class.ClassDate), "day") &&
              c.HeightSortOrder >= foundDog.LowestRegularHeight
          );
          setAvailableCKOHeights(found);
        }
      }
      setTimeout(() => {
        Loading.current = false;
      }, 2000);
    }
  }, [props.Data, props.DogId, props.Class, User, itm]);

  //Handlers for team/pair dog selection changes
  useEffect(() => {
    if (
      TPTeamName !== "" &&
      SecondTeamDog &&
      SecondTeamHandler &&
      SecondTeamDogHeight &&
      SecondTeamDogHeight !== "" &&
      ThirdTeamDog &&
      ThirdTeamHandler &&
      ThirdTeamDogHeight &&
      ThirdTeamDogHeight !== ""
    ) {
      setTeamCanSave(true);
    } else {
      setTeamCanSave(false);
    }
  }, [
    TPTeamName,
    SecondTeamDog,
    SecondTeamHandler,
    SecondTeamDogHeight,
    ThirdTeamDog,
    ThirdTeamHandler,
    ThirdTeamDogHeight,
  ]);

  useEffect(() => {
    if (
      SecondPairDog &&
      SecondPairHandler &&
      SecondPairDogHeight &&
      SecondPairDogHeight !== "" &&
      SecondPairDogSector &&
      SecondPairDogSector !== ""
    ) {
      setPairCanSave(true);
    } else {
      setPairCanSave(false);
    }
  }, [SecondPairDog, SecondPairHandler, SecondPairDogHeight, SecondPairDogSector]);

  useEffect(() => {
    if (SecondTeamDog) {
      if (SecondTeamDog.heightid) {
        setSecondTeamDogHeight(SecondTeamDog.heightid);
      } else {
        setSecondTeamDogHeight(1);
      }
      if (SecondTeamDog.handler) {
        const Handler = SecondTeamDog.handler;
        setSecondTeamHandler({
          value: Handler.Id,
          label: `${Handler.Id} - ${Handler.Name}`,
          name: Handler.Name,
        });
      }
    } else {
      setSecondTeamDogHeight();
      setSecondTeamHandler(undefined);
    }
  }, [SecondTeamDog]);

  useEffect(() => {
    if (SecondTeamDog && SecondTeamDogHeight) {
      if (parseInt(SecondTeamDogHeight) < SecondTeamDog.heightid) {
        setShowInvalidHeight(true);
        setTimeout(() => {
          setShowInvalidHeight(false);
        }, 3000);
        setSecondTeamDogHeight(SecondTeamDog.heightid);
      }
    }
  }, [SecondTeamDog, SecondTeamDogHeight]);

  useEffect(() => {
    if (ThirdTeamDog && ThirdTeamDogHeight) {
      if (parseInt(ThirdTeamDogHeight) < ThirdTeamDog.heightid) {
        setShowInvalidHeight(true);
        setTimeout(() => {
          setShowInvalidHeight(false);
        }, 3000);
        setThirdTeamDogHeight(ThirdTeamDog.heightid);
      }
    }
  }, [ThirdTeamDog, ThirdTeamDogHeight]);

  useEffect(() => {
    if (ThirdTeamDog) {
      if (ThirdTeamDog.heightid) {
        setThirdTeamDogHeight(ThirdTeamDog.heightid);
      } else {
        setThirdTeamDogHeight(1);
      }
      if (ThirdTeamDog.handler) {
        const Handler = ThirdTeamDog.handler;
        setThirdTeamHandler({
          value: Handler.Id,
          label: `${Handler.Id} - ${Handler.Name}`,
          name: Handler.Name,
        });
      }
    } else {
      setThirdTeamHandler(undefined);
    }
  }, [ThirdTeamDog]);

  useEffect(() => {
    if (SecondPairDog) {
      if (SecondPairDog.heightid) {
        setSecondPairDogHeight(SecondPairDog.heightid);
      } else {
        setSecondPairDogHeight(1);
      }
      if (SecondPairDog.handler) {
        const Handler = SecondPairDog.handler;
        setSecondPairHandler({
          value: Handler.Id,
          label: `${Handler.Id} - ${Handler.Name}`,
          name: Handler.Name,
        });
      }
    } else {
      setSecondPairDogHeight();
      setSecondPairHandler(undefined);
    }
  }, [SecondPairDog]);

  useEffect(() => {
    if (SecondPairDog && SecondPairDogHeight) {
      if (parseInt(SecondPairDogHeight) < SecondPairDog.heightid) {
        setShowInvalidHeight(true);
        setTimeout(() => {
          setShowInvalidHeight(false);
        }, 3000);
        setSecondPairDogHeight(SecondPairDog.heightid);
      }
    }
  }, [SecondPairDog, SecondPairDogHeight]);

  useEffect(() => {
    //Check for Admin login
    let itm = sessionStorage.getItem("User");
    itm = JSON.parse(itm);
    if (itm.IsAdminLogin) setIsAdminLogin(true);
  }, []);

  useEffect(() => {
    if (props.Class.ClassId === 25 && Checked && Loading.current === false) {
      setShowChooseHeight(true);
    }
  }, [Checked]);

  //Make a selection / deselection
  const MakeSelection = (
    SelectedClassType,
    ClassId,
    ClassDate,
    HeightId,
    LevelId,
    Selected,
    SelectedHandler = null
  ) => {
    let FoundDog = props.Data.Dogs.find((d) => d.Id === parseInt(props.DogId));
    const Choices = FoundDog?.Choices || [];
    let NewChoices = Array.from(Choices);
    let dialogVisible = false;

    //Count how many casual / non casual classes we have
    const CasualClassCount = Choices.filter(
      (c) => (c.SelectedClassType === 8 || c.LevelId === 7) && moment(c.ClassDate).isSame(moment(ClassDate))
    );
    const NonCasualClassCount = Choices.filter(
      (c) => c.SelectedClassType !== 8 && c.LevelId !== 7 && moment(c.ClassDate).isSame(moment(ClassDate))
    );
    const NurseryClassCount = Choices.filter(
      (c) => c.SelectedClassType === 5 && moment(c.ClassDate).isSame(moment(ClassDate))
    );
    const SteeplechaseClassCount = Choices.filter(
      (c) => c.SelectedClassType === 4 && moment(c.ClassDate).isSame(moment(ClassDate))
    );
    const NonNurseryClassCount = Choices.filter(
      (c) => c.SelectedClassType !== 5 && moment(c.ClassDate).isSame(moment(ClassDate))
    );
    const OtherClassTypes = Choices.filter(
      (c) => c.SelectedClassType !== 5 && c.SelectedClassType !== 4 && moment(c.ClassDate).isSame(moment(ClassDate))
    );

    if (Selected) {
      //Casual rules
      if (SelectedClassType === 8 || LevelId === 7) {
        if (NonCasualClassCount.length > 0) {
          const YesSelected = () => {
            setShowMessageModal(false);
            NewChoices = NewChoices.filter(
              (c) =>
                ((c.SelectedClassType === 8 || c.LevelId === 7) && moment(c.ClassDate).isSame(moment(ClassDate))) ||
                !moment(c.ClassDate).isSame(moment(ClassDate))
            );
            FinaliseSelection(
              SelectedClassType,
              ClassId,
              ClassDate,
              HeightId,
              LevelId,
              Selected,
              SelectedHandler,
              NewChoices,
              FoundDog
            );
          };
          setMessageModalContent(
            <>
              <p>
                You cannot combine casual with other classes on the same day. This will remove all non-casual classes
                you have selected. Are you sure?
              </p>
              <p>
                <button className="button button-green mr-1x" onClick={YesSelected}>
                  Yes
                </button>
                <button className="button" onClick={() => setShowMessageModal(false)}>
                  No
                </button>
              </p>
            </>
          );
          setShowMessageModal(true);
          dialogVisible = true;
        }
      } else {
        if (CasualClassCount.length > 0) {
          const YesSelected = () => {
            setShowMessageModal(false);
            NewChoices = NewChoices.filter(
              (c) =>
                (c.SelectedClassType !== 8 && c.LevelId !== 7 && moment(c.ClassDate).isSame(moment(ClassDate))) ||
                !moment(c.ClassDate).isSame(moment(ClassDate))
            );
            FinaliseSelection(
              SelectedClassType,
              ClassId,
              ClassDate,
              HeightId,
              LevelId,
              Selected,
              SelectedHandler,
              NewChoices,
              FoundDog
            );
          };
          setMessageModalContent(
            <>
              <p>
                You cannot combine casual with other classes on the same day. This will remove all casual classes you
                have selected. Are you sure?
              </p>
              <p>
                <button className="button button-green mr-1x" onClick={YesSelected}>
                  Yes
                </button>
                <button className="button" onClick={() => setShowMessageModal(false)}>
                  No
                </button>
              </p>
            </>
          );
          setShowMessageModal(true);
          dialogVisible = true;
        }
      }

      //Nursery Steeplechase rules
      if (SelectedClassType === 5) {
        //Other classes but no steeplechase classes selected
        if (SteeplechaseClassCount.length === 0 && NonNurseryClassCount.length > 0) {
          const YesSelected = () => {
            setShowMessageModal(false);
            NewChoices = NewChoices.filter(
              (c) =>
                (c.SelectedClassType === 5 && moment(c.ClassDate).isSame(moment(ClassDate))) ||
                !moment(c.ClassDate).isSame(moment(ClassDate))
            );
            FinaliseSelection(
              SelectedClassType,
              ClassId,
              ClassDate,
              HeightId,
              LevelId,
              Selected,
              SelectedHandler,
              NewChoices,
              FoundDog
            );
          };
          setMessageModalContent(
            <>
              <p>
                You cannot combine nursery with other non-steeplechase classes on the same day. This will remove all
                classes other than Nursery you have selected. Are you sure?
              </p>
              <p>
                <button className="button button-green mr-1x" onClick={YesSelected}>
                  Yes
                </button>
                <button className="button" onClick={() => setShowMessageModal(false)}>
                  No
                </button>
              </p>
            </>
          );
          setShowMessageModal(true);
          dialogVisible = true;
        } else if (
          SteeplechaseClassCount.length > 0 &&
          NonNurseryClassCount.length > 0 &&
          OtherClassTypes.length === 0
        ) {
          FinaliseSelection(
            SelectedClassType,
            ClassId,
            ClassDate,
            HeightId,
            LevelId,
            Selected,
            SelectedHandler,
            NewChoices,
            FoundDog
          );
        } else if (SteeplechaseClassCount.length > 0 && NonNurseryClassCount.length > 0) {
          //Other classes and steeplechase

          const YesSelected = () => {
            setShowMessageModal(false);
            NewChoices = NewChoices.filter(
              (c) =>
                ((c.SelectedClassType === 4 || c.SelectedClassType === 5) &&
                  moment(c.ClassDate).isSame(moment(ClassDate))) ||
                !moment(c.ClassDate).isSame(moment(ClassDate))
            );
            FinaliseSelection(
              SelectedClassType,
              ClassId,
              ClassDate,
              HeightId,
              LevelId,
              Selected,
              SelectedHandler,
              NewChoices,
              FoundDog
            );
          };
          setMessageModalContent(
            <>
              <p>
                You cannot combine nursery with other non-steeplechase classes on the same day. This will remove all
                classes other than Nursery / Steeplechase you have selected. Are you sure?
              </p>
              <p>
                <button className="button button-green mr-1x" onClick={YesSelected}>
                  Yes
                </button>
                <button className="button" onClick={() => setShowMessageModal(false)}>
                  No
                </button>
              </p>
            </>
          );
          setShowMessageModal(true);
          dialogVisible = true;
        }
      } else {
        //If not Steeplechase
        if (SelectedClassType !== 4) {
          if (NurseryClassCount.length > 0) {
            const YesSelected = () => {
              setShowMessageModal(false);
              NewChoices = NewChoices.filter(
                (c) =>
                  (c.SelectedClassType !== 5 && moment(c.ClassDate).isSame(moment(ClassDate))) ||
                  !moment(c.ClassDate).isSame(moment(ClassDate))
              );
              FinaliseSelection(
                SelectedClassType,
                ClassId,
                ClassDate,
                HeightId,
                LevelId,
                Selected,
                SelectedHandler,
                NewChoices,
                FoundDog
              );
            };
            setMessageModalContent(
              <>
                <p>
                  You cannot combine nursery with other non-steeplechase classes on the same day. This will remove all
                  Nursery classes you have selected. Are you sure?
                </p>
                <p>
                  <button className="button button-green mr-1x" onClick={YesSelected}>
                    Yes
                  </button>
                  <button className="button" onClick={() => setShowMessageModal(false)}>
                    No
                  </button>
                </p>
              </>
            );
            setShowMessageModal(true);
            dialogVisible = true;
          }
        }
      }
    }

    //If we are not having to prompt about Nursery / Casual we can continue straight away
    if (!dialogVisible)
      FinaliseSelection(
        SelectedClassType,
        ClassId,
        ClassDate,
        HeightId,
        LevelId,
        Selected,
        SelectedHandler,
        NewChoices,
        FoundDog
      );
  };

  const FinaliseSelection = (
    SelectedClassType,
    ClassId,
    ClassDate,
    HeightId,
    LevelId,
    Selected,
    SelectedHandler,
    NewChoices,
    FoundDog
  ) => {
    //Build details of class entry
    const DefaultHandlerName = DefaultHandler.label.split(" ");
    const obj = {
      SelectedClassType,
      ShowId: parseInt(ShowId),
      MemberId: User.Id,
      HandlerId: DefaultHandler.value,
      DogId: parseInt(props.DogId),
      ClassDate,
      ClassId,
      HeightId,
      LevelId: ClassId === 32 ? 99 : LevelId,
      FirstName: DefaultHandlerName.length < 3 ? DefaultHandlerName[0] : DefaultHandlerName[2],
      LastName: DefaultHandlerName.length < 3 ? DefaultHandlerName[1] : DefaultHandlerName[3],
      IsPreEntry: !props.LateEntry,
      IsLateEntry: props.LateEntry,
    };

    //Add if this is a section, otherwise add everything that isn't this as it's a deselection
    if (Selected) {
      if (ClassId === 25) NewChoices = _.remove(NewChoices, (r) => r.ClassId !== 25);
      NewChoices.push(obj);
    } else {
      NewChoices = _.remove(NewChoices, (r) => {
        return (
          r.SelectedClassType !== SelectedClassType ||
          r.ClassDate !== ClassDate ||
          r.ClassId !== ClassId ||
          r.HeightId !== HeightId ||
          r.LevelId !== LevelId
        );
      });
      if (ClassId === 25) {
        NewChoices = _.remove(NewChoices, (r) => {
          return (
            r.SelectedClassType !== SelectedClassType ||
            r.ClassDate !== ClassDate ||
            r.ClassId !== ClassId ||
            r.HeightId !== CKOHeightId ||
            r.LevelId !== LevelId
          );
        });
      }
    }

    //Create 'dog' if it's the first selection for this dog
    if (!FoundDog) {
      FoundDog = {
        Id: parseInt(props.DogId),
        Choices: NewChoices,
      };
    } else {
      FoundDog.Choices = NewChoices;
    }

    //Recreate dogs and choices array
    //Select everything that is not this dog
    let NotThisDog = props.Data.Dogs.filter((d) => d.Id !== parseInt(props.DogId));
    //Add together with the selected dog
    const Final = [...NotThisDog, FoundDog];
    props.SetDogs(Final);
  };

  const SetHandler = (SelectedClassType, ClassId, ClassDate, HeightId, LevelId, SelectedHandler) => {
    const FoundDog = props.Data.Dogs.find((d) => d.Id === parseInt(props.DogId));
    const Choices = FoundDog.Choices;
    const FoundClass = Choices.find(
      (c) =>
        c.SelectedClassType === SelectedClassType &&
        c.ClassId === ClassId &&
        moment(c.ClassDate).isSame(moment(ClassDate)) &&
        c.HeightId === HeightId &&
        c.LevelId === LevelId
    );
    FoundClass.HandlerId = SelectedHandler.value;
    const name = SelectedHandler.name.split(" ");
    FoundClass.FirstName = name[0];
    FoundClass.LastName = name[1];

    //Recreate dogs and choices array
    //Select everything that is not this dog
    let NotThisDog = props.Data.Dogs.filter((d) => d.Id !== parseInt(props.DogId));
    //Add together with the selected dog
    const Final = [...NotThisDog, FoundDog];
    props.SetDogs(Final);
  };

  const GetDogSuggestions = async (val) => {
    try {
      const obj = {
        ShowId,
        ClassId: TPClassId,
        ClassDate: TPClassDate,
        LeadDogId: parseInt(props.DogId),
        SearchString: val,
      };
      const req = await apiPost("/shows/tp/geteligibledogs", obj);
      if (req && req.ok && req.status === 200) {
        const includedIds = [];
        props.Data.Dogs.map((itm, acc) => {
          for (const choice of itm.Choices) {
            if (choice.Pair) {
              includedIds.push(choice.DogId);
              includedIds.push(choice.Pair?.SecondDog?.Id);
            }
            if (choice.Team) {
              includedIds.push(choice.DogId);
              includedIds.push(choice.Team?.SecondDog?.Id);
              includedIds.push(choice.Team?.ThirdDog?.Id);
            }
          }
        }, []);

        const res = await req.json();
        return res.map((itm) => {
          let displayString = `${itm.Id} ${itm.PetName}`;

          if (itm.FirstName !== "Unknown") displayString += ` - ${itm.FirstName} ${itm.LastName}`;
          if (itm.IsDisabled || includedIds.indexOf(itm.Id) !== -1) displayString += " (Unavailable)";

          return {
            value: itm.Id,
            label: displayString,
            isDisabled: itm.IsDisabled || includedIds.indexOf(itm.Id) !== -1,
            heightid: itm.RegularPerformanceHeight !== null ? itm.RegularPerformanceHeight : itm.HeightId,
            petname: itm.PetName,
            handler: {
              Id: itm.MemberId,
              Name: `${itm.FirstName} ${itm.LastName}`,
            },
          };
        });
      } else {
        return [];
      }
    } catch (error) {
      console.log(error.message);
      return [];
    }
  };

  const doTeam = () => {
    setTPClassId(props.Class.ClassId);
    setTPClassDate(props.Class.ClassDate);

    try {
      const FoundDog = props.Data.Dogs.find((d) => d.Id === parseInt(props.DogId));
      const FoundRow = FoundDog.Choices.find(
        (c) => c.ClassId === props.Class.ClassId && moment(c.ClassDate).isSame(moment(props.Class.ClassDate))
      );
      if (FoundRow.Team) {
        const itm = FoundRow.Team;
        setSecondTeamDog({
          value: itm.SecondDog.Id,
          label: itm.SecondDog.PetName,
          petname: itm.SecondDog.PetName,
          heightid: itm.SecondDog.heightid,
          handler: {
            Id: itm.SecondDog.Handler.Id,
            Name: itm.SecondDog.Handler.Name,
          },
        });
        setSecondTeamDogHeight(itm.SecondDog.HeightId);
        setSecondTeamHandler({
          value: itm.SecondDog.Handler.Id,
          label: itm.SecondDog.Handler.Name,
          name: itm.SecondDog.Handler.Name,
        });
        setThirdTeamDog({
          value: itm.ThirdDog.Id,
          label: itm.ThirdDog.PetName,
          petname: itm.ThirdDog.PetName,
          heightid: itm.ThirdDog.heightid,
          handler: {
            Id: itm.ThirdDog.Handler.Id,
            Name: itm.ThirdDog.Handler.Name,
          },
        });
        setThirdTeamDogHeight(itm.ThirdDog.HeightId);
        setThirdTeamHandler({
          value: itm.ThirdDog.Handler.Id,
          label: itm.ThirdDog.Handler.Name,
          name: itm.ThirdDog.Handler.Name,
        });
        setTPTeamName(itm.TeamName);
      } else {
        setSecondTeamDog();
        setSecondTeamDogHeight();
        setSecondTeamHandler();
        setThirdTeamDog();
        setThirdTeamDogHeight();
        setThirdTeamHandler();
        setTPTeamName("");
      }
    } catch (error) {
      console.log(error);
    }

    setShowTeamModal(true);
  };

  const doPair = () => {
    setTPClassId(props.Class.ClassId);
    setTPClassDate(props.Class.ClassDate);

    try {
      const FoundDog = props.Data.Dogs.find((d) => d.Id === parseInt(props.DogId));
      const FoundRow = FoundDog.Choices.find(
        (c) => c.ClassId === props.Class.ClassId && moment(c.ClassDate).isSame(moment(props.Class.ClassDate))
      );
      if (FoundRow.Pair) {
        const itm = FoundRow.Pair;
        setSecondPairDog({
          value: itm.SecondDog.Id,
          label: itm.SecondDog.PetName,
          petname: itm.SecondDog.PetName,
          heightid: itm.SecondDog.heightid,
          handler: {
            Id: itm.SecondDog.Handler.Id,
            Name: itm.SecondDog.Handler.Name,
          },
        });
        setSecondPairDogHeight(itm.SecondDog.HeightId);
        setSecondPairDogSector(itm.SecondDog.Sector);
        setSecondPairHandler({
          value: itm.SecondDog.Handler.Id,
          label: itm.SecondDog.Handler.Name,
          name: itm.SecondDog.Handler.Name,
        });
      } else {
        setSecondPairDog();
        setSecondPairDogHeight();
        setSecondPairDogSector();
        setSecondPairHandler();
      }
    } catch (error) {
      console.log(error);
    }

    setShowPairModal(true);
  };

  const SaveTeam = async () => {
    const FoundDog = props.Data.Dogs.find((d) => d.Id === parseInt(props.DogId));
    const FoundClass = FoundDog.Choices.find(
      (c) => c.ClassId === TPClassId && moment(c.ClassDate).isSame(moment(TPClassDate))
    );
    FoundClass.Team = {
      TeamName: TPTeamName,
      SecondDog: {
        Id: SecondTeamDog?.value,
        PetName: SecondTeamDog?.petname,
        HeightId: SecondTeamDogHeight ? parseInt(SecondTeamDogHeight) : undefined,
        Handler: {
          Id: SecondTeamHandler?.value,
          Name: SecondTeamHandler?.name,
        },
      },
      ThirdDog: {
        Id: ThirdTeamDog?.value,
        PetName: ThirdTeamDog?.petname,
        HeightId: ThirdTeamDogHeight ? parseInt(ThirdTeamDogHeight) : undefined,
        Handler: {
          Id: ThirdTeamHandler?.value,
          Name: ThirdTeamHandler?.name,
        },
      },
    };

    setShowTeamModal(false);
  };

  const SavePair = async () => {
    const FoundDog = props.Data.Dogs.find((d) => d.Id === parseInt(props.DogId));
    const FoundClass = FoundDog.Choices.find(
      (c) => c.ClassId === TPClassId && moment(c.ClassDate).isSame(moment(TPClassDate))
    );
    FoundClass.Pair = {
      SecondDog: {
        Id: SecondPairDog?.value,
        PetName: SecondPairDog?.petname,
        HeightId: SecondPairDogHeight ? parseInt(SecondPairDogHeight) : undefined,
        Sector: SecondPairDogSector,
        Handler: {
          Id: SecondPairHandler?.value,
          Name: SecondPairHandler?.name,
        },
      },
    };

    setShowPairModal(false);
  };

  //Gets suggested handler names from the server
  const GetHandlerSuggestions = async (val) => {
    try {
      const req = await apiGet(`/members/match/${val}`);
      if (req && req.ok && req.status === 200) {
        const res = await req.json();
        return res
          .filter((m) => m.Status === 1)
          .map((itm) => {
            const displayString = `${itm.Id} - ${itm.FirstName} ${itm.LastName}`;
            return {
              value: itm.Id,
              label: displayString,
              name: `${itm.FirstName} ${itm.LastName}`,
            };
          });
      } else {
        return [];
      }
    } catch (error) {
      console.log(error.message);
      return [];
    }
  };

  const AvailableHeights = props.Data.HeightList.filter((itm) => !itm.IsSelect && itm.HeightId !== 9).map((itm) => (
    <option key={itm.HeightId} value={itm.HeightId}>
      {itm.HeightLabel}
    </option>
  ));

  if (!props.Class) return <></>;
  if (
    props.Class &&
    props.LateEntry &&
    (props.Class?.ClassId === 23 || props.Class?.ClassId === 24 || props.Class?.ClassId === 25)
  )
    return (
      <tr>
        <td>
          {itm.ClassLabel}
          {itm.Sponsor ? (
            <>
              <br />
              {itm.Sponsor}
            </>
          ) : (
            <></>
          )}
        </td>
        <td colSpan={6}>Late entries only available for this class at the show</td>
      </tr>
    );

  return (
    <>
      <tr>
        {TeamPairMember ? (
          <>
            <td data-mobilelabel="Class:">
              {itm.ClassLabel}
              {itm.Sponsor ? (
                <>
                  <br />
                  {itm.Sponsor}
                </>
              ) : (
                <></>
              )}
            </td>
            <td data-mobilelabel="Details:" colSpan={6}>
              <p>This dog is already entered in this class.</p>
            </td>
          </>
        ) : (
          <>
            <td data-mobilelabel="Class:">
              {ShowMessageModal ? <Modal>{MessageModalContent}</Modal> : <></>}

              {ShowPairModal ? (
                <Modal View="Wide">
                  <div className="grid">
                    <div className="col-6">
                      <p className="mt-1x">
                        Please provide details of your second dog below and click 'Save Details' to update your entry.
                        <br />
                        Enter a dog name and the handler will be automatically entered. Change the handler name after
                        selecting a dog if you need to.
                        <br />
                        If the dog you are trying to select shows as 'Unavailable' it is in another pair already.
                      </p>
                      <p>
                        Please be aware that any member of a team/pair can cancel the entry through their account. You
                        will receive an email if this occurs.
                      </p>

                      <p className="mb-2x">
                        If you wish to change a dog or handler after you have selected them please click on the x next
                        to their name.
                      </p>
                    </div>
                    <div className="col-6 ml-3x">
                      <h4>Checklist</h4>
                      <ul className="no-bullets">
                        <li>
                          {SecondPairDog ? (
                            <FontAwesomeIcon icon={faCheck} className="text-green mr-1x" />
                          ) : (
                            <FontAwesomeIcon icon={faTimes} className="text-red mr-1x" />
                          )}
                          Dog
                        </li>
                        <li>
                          {SecondPairHandler ? (
                            <FontAwesomeIcon icon={faCheck} className="text-green mr-1x" />
                          ) : (
                            <FontAwesomeIcon icon={faTimes} className="text-red mr-1x" />
                          )}
                          Handler
                        </li>
                        <li>
                          {SecondPairDogHeight && SecondPairDogHeight !== "" ? (
                            <FontAwesomeIcon icon={faCheck} className="text-green mr-1x" />
                          ) : (
                            <FontAwesomeIcon icon={faTimes} className="text-red mr-1x" />
                          )}
                          Height
                        </li>
                        <li>
                          {SecondPairDogSector && SecondPairDogSector !== "" ? (
                            <FontAwesomeIcon icon={faCheck} className="text-green mr-1x" />
                          ) : (
                            <FontAwesomeIcon icon={faTimes} className="text-red mr-1x" />
                          )}
                          Course
                        </li>
                      </ul>
                    </div>
                  </div>

                  {ShowInvalidHeight ? (
                    <p className="mb-2x alert alert-error">
                      This dog is not eligible to run at the selected height. You need to select your regular height or
                      above.
                    </p>
                  ) : (
                    <></>
                  )}

                  <h4>Second dog</h4>
                  <div className="grid mb-3">
                    <div className="col-4">
                      <p>Dog</p>
                      <AsyncSelect
                        components={{ LoadingIndicator }}
                        isClearable
                        className="width-75"
                        placeholder="Start typing"
                        value={SecondPairDog}
                        onChange={(e) => setSecondPairDog(e)}
                        loadOptions={GetDogSuggestions}
                      />
                    </div>
                    <div className="col-4">
                      <p>Handler</p>
                      <AsyncSelect
                        components={{ LoadingIndicator }}
                        isClearable
                        className="width-75"
                        placeholder="Start typing"
                        value={SecondPairHandler}
                        onChange={(e) => setSecondPairHandler(e)}
                        loadOptions={GetHandlerSuggestions}
                      />
                    </div>
                    <div className="col-2">
                      <p>Height</p>
                      <select
                        className="form-control width-75"
                        value={SecondPairDogHeight}
                        onChange={(e) => setSecondPairDogHeight(e.target.value)}>
                        <option value="">Please select...</option>
                        {AvailableHeights}
                      </select>
                      <br />
                    </div>
                    <div className="col-2">
                      <p>Course</p>
                      <select
                        className="form-control width-75"
                        value={SecondPairDogSector}
                        onChange={(e) => setSecondPairDogSector(e.target.value)}>
                        <option value="">Select...</option>
                        <option value="Agility">Agility</option>
                        <option value="Jumping">Jumping</option>
                      </select>
                    </div>
                  </div>
                  <div className="mt-2x">
                    {PairCanSave ? (
                      <button className="button button-green mr-1x" onClick={SavePair}>
                        Save Details
                      </button>
                    ) : (
                      <span className="text-red text-bold mr-2x">Missing data - see checklist above</span>
                    )}
                    <button className="button button-link" onClick={() => setShowPairModal(false)}>
                      Cancel
                    </button>
                  </div>
                </Modal>
              ) : (
                <></>
              )}

              {ShowTeamModal ? (
                <Modal View="Wide">
                  <div className="grid">
                    <div className="col-6">
                      <p className="mt-1x">
                        Please provide details of your second and third dogs below and click 'Save Details' to update
                        your entry.
                        <br />
                        Enter a dog name and the handler will be automatically entered. Change the handler name after
                        selecting a dog if you need to.
                        <br />
                        If the dog you are trying to select shows as 'Unavailable' it is in another team already.
                      </p>
                      <p>
                        Please be aware that any member of a team/pair can cancel the entry through their account. You
                        will receive an email if this occurs.
                      </p>
                      <p className="mb-2x">
                        If you wish to change a dog or handler after you have selected them please click on the x next
                        to their name.
                      </p>
                    </div>
                    <div className="col-6 ml-3x">
                      <h4>Checklist</h4>
                      <ul className="no-bullets">
                        <li>
                          {SecondTeamDog ? (
                            <FontAwesomeIcon icon={faCheck} className="text-green mr-1x" />
                          ) : (
                            <FontAwesomeIcon icon={faTimes} className="text-red mr-1x" />
                          )}
                          Second dog
                        </li>
                        <li>
                          {SecondTeamHandler ? (
                            <FontAwesomeIcon icon={faCheck} className="text-green mr-1x" />
                          ) : (
                            <FontAwesomeIcon icon={faTimes} className="text-red mr-1x" />
                          )}
                          Second handler
                        </li>
                        <li>
                          {SecondTeamDogHeight && SecondTeamDogHeight !== "" ? (
                            <FontAwesomeIcon icon={faCheck} className="text-green mr-1x" />
                          ) : (
                            <FontAwesomeIcon icon={faTimes} className="text-red mr-1x" />
                          )}
                          Second dog height
                        </li>
                        <li>
                          {ThirdTeamDog ? (
                            <FontAwesomeIcon icon={faCheck} className="text-green mr-1x" />
                          ) : (
                            <FontAwesomeIcon icon={faTimes} className="text-red mr-1x" />
                          )}
                          Third dog
                        </li>
                        <li>
                          {ThirdTeamHandler ? (
                            <FontAwesomeIcon icon={faCheck} className="text-green mr-1x" />
                          ) : (
                            <FontAwesomeIcon icon={faTimes} className="text-red mr-1x" />
                          )}
                          Third handler
                        </li>
                        <li>
                          {ThirdTeamDogHeight && ThirdTeamDogHeight !== "" ? (
                            <FontAwesomeIcon icon={faCheck} className="text-green mr-1x" />
                          ) : (
                            <FontAwesomeIcon icon={faTimes} className="text-red mr-1x" />
                          )}
                          Third dog height
                        </li>
                        <li>
                          {TPTeamName ? (
                            <FontAwesomeIcon icon={faCheck} className="text-green mr-1x" />
                          ) : (
                            <FontAwesomeIcon icon={faTimes} className="text-red mr-1x" />
                          )}
                          Team Name
                        </li>
                      </ul>
                    </div>
                  </div>

                  <p className="mb-2x">
                    <b>Team Name</b>
                    <br />
                    <input
                      type="text"
                      className="form-control"
                      value={TPTeamName}
                      onChange={(e) => setTPTeamName(e.target.value)}
                    />
                  </p>

                  {ShowInvalidHeight ? (
                    <p className="mb-2x alert alert-error">
                      This dog is not eligible to run at the selected height. You need to select your regular height or
                      above.
                    </p>
                  ) : (
                    <></>
                  )}

                  <h4>Second dog</h4>
                  <div className="grid mb-3x">
                    <div className="col-4">
                      <p>Dog</p>
                      <AsyncSelect
                        components={{ LoadingIndicator }}
                        isClearable
                        className="width-75"
                        placeholder="Start typing"
                        value={SecondTeamDog}
                        onChange={(e) => setSecondTeamDog(e)}
                        loadOptions={GetDogSuggestions}
                      />
                    </div>
                    <div className="col-4">
                      <p>Handler</p>
                      <AsyncSelect
                        components={{ LoadingIndicator }}
                        isClearable
                        className="width-75"
                        placeholder="Start typing"
                        value={SecondTeamHandler}
                        onChange={(e) => setSecondTeamHandler(e)}
                        loadOptions={GetHandlerSuggestions}
                      />
                    </div>
                    <div className="col-4">
                      <p>Height</p>
                      <select
                        className="form-control width-75"
                        value={SecondTeamDogHeight}
                        onChange={(e) => setSecondTeamDogHeight(e.target.value)}>
                        <option value="">Please select...</option>
                        {AvailableHeights}
                      </select>
                    </div>
                  </div>

                  <h4>Third dog</h4>
                  <div className="grid mb-3x">
                    <div className="col-4">
                      <p>Dog</p>
                      <AsyncSelect
                        components={{ LoadingIndicator }}
                        isClearable
                        className="width-75"
                        placeholder="Start typing"
                        value={ThirdTeamDog}
                        onChange={(e) => setThirdTeamDog(e)}
                        loadOptions={GetDogSuggestions}
                      />
                    </div>
                    <div className="col-4">
                      <p>Handler</p>
                      <AsyncSelect
                        components={{ LoadingIndicator }}
                        isClearable
                        className="width-75"
                        placeholder="Start typing"
                        value={ThirdTeamHandler}
                        onChange={(e) => setThirdTeamHandler(e)}
                        loadOptions={GetHandlerSuggestions}
                      />
                    </div>
                    <div className="col-4">
                      <p>Height</p>
                      <select
                        className="form-control width-75"
                        value={ThirdTeamDogHeight}
                        onChange={(e) => setThirdTeamDogHeight(e.target.value)}>
                        <option value="">Please select...</option>
                        {AvailableHeights}
                      </select>
                    </div>
                  </div>

                  <div className="mt-2x">
                    {TeamCanSave ? (
                      <button className="button button-green mr-1x" onClick={SaveTeam}>
                        Save Details
                      </button>
                    ) : (
                      <span className="text-red text-bold mr-2x">Missing data - see checklist above</span>
                    )}
                    <button className="button button-link" onClick={() => setShowTeamModal(false)}>
                      Cancel
                    </button>
                  </div>
                </Modal>
              ) : (
                <></>
              )}

              {ShowChooseHeight && (
                <Modal>
                  <h3>Choose your height for CKO</h3>
                  <div className="form-group">
                    <label>Height</label>
                    <select
                      className="form-control"
                      value={CKOHeightSelection}
                      onChange={(e) => setCKOHeightSelection(e.target.value)}>
                      {AvailableCKOHeights.map((itm) => (
                        <option key={itm.HeightId} value={itm.HeightId}>
                          {itm.HeightLabel}
                        </option>
                      ))}
                    </select>
                  </div>
                  <p>
                    The heights shown are the only options for this day. If you wish to enter at another eligible
                    height, you should enter at your measured height now and then amend this on the first CKO heat day
                    at the show.
                  </p>
                  <p>
                    <button className="button button-green mr-1x" onClick={MakeCKOHeightSelection}>
                      OK
                    </button>
                    <button className="button" onClick={() => setShowChooseHeight(false)}>
                      Cancel
                    </button>
                  </p>
                </Modal>
              )}

              {itm.ClassLabel}
              {itm.Sponsor ? (
                <>
                  <br />
                  {itm.Sponsor}
                </>
              ) : (
                <></>
              )}
            </td>
            <td data-mobilelabel="Level:">{itm.LevelLabel}</td>
            <td data-mobilelabel="Height:">{HeightLabel}</td>
            {
              //(!itm.Capped || !props.Data.FirstTime) || Checked ?
              Available || Checked ? (
                <td data-mobilelabel="Select?">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={Checked}
                      onChange={(e) =>
                        MakeSelection(
                          itm.ClassType,
                          itm.ClassId,
                          itm.ClassDate,
                          itm.HeightId,
                          itm.LevelId,
                          e.target.checked
                        )
                      }
                    />
                    <span className="slider round"></span>
                  </label>
                </td>
              ) : (
                <td data-mobilelabel="Select?">
                  <p>This day has capped</p>
                </td>
              )
            }
            <td data-mobilelabel="Price:">£{numeral(itm.RunCost).format("0.00")}</td>
            <td className="width-25">
              {Checked ? (
                <AsyncSelect
                  noOptionsMessage={() => "Type a name"}
                  placeholder="Start typing"
                  value={DefaultHandler}
                  onChange={(e) => SetHandler(itm.ClassType, itm.ClassId, itm.ClassDate, itm.HeightId, itm.LevelId, e)}
                  loadOptions={GetHandlerSuggestions}
                />
              ) : (
                <></>
              )}
            </td>
            <td className="centre">
              {itm.IsTeam && Checked ? (
                <button className="button button-blue" onClick={doTeam}>
                  Enter Team Details
                </button>
              ) : (
                <></>
              )}
              {itm.IsPairs && Checked ? (
                <button className="button button-blue" onClick={doPair}>
                  Enter Pair Details
                </button>
              ) : (
                <></>
              )}
            </td>
          </>
        )}
      </tr>
    </>
  );
};

export default ClassView;
